import { cn } from "~/util/cn";

export interface Props
  extends React.ButtonHTMLAttributes<
    HTMLButtonElement | HTMLAnchorElement | HTMLInputElement
  > {
  look?: "default" | "blu" | "yello" | "link" | "danger";
  tag?: "button" | "a" | "input";
  size?: "small" | "default";
}

const classes = {
  default:
    "border-gray-400 text-gray-500 hover:bg-gray-500 hover:border-gray-200 hover:text-white transition-colors",
  yello:
    "bg-yello-500 border-yello-500 text-black hover:bg-yello-400 hover:border-yello-500 hover:text-gray-900 transition-colors",
  blu: "bg-blu-400 border-blu-300 text-white hover:bg-blu-300 hover:border-blu-200 transition-colors",
  link: "border-none shadow-none bg-transparent text-inherit hover:underline p-0 font-normal hover:bg-transparent",
  danger:
    "border-red-500 text-red-500 hover:bg-red-500 hover:border-red-500 hover:text-white transition-colors",
};

const Button: React.FC<Props> = ({
  look: type = "default",
  tag = "button",
  size = "default",
  className,
  ...props
}) => {
  const Tag = tag;
  return (
    <Tag
      {...props}
      className={cn(
        "hover:bg-yello-300 flex flex w-fit shrink-0 items-center justify-center rounded-md border border-[0.1rem] border-black bg-transparent px-[1em] py-1.5 text-base font-medium shadow transition-colors hover:cursor-pointer hover:border-gray-600",
        classes[type],
        size === "small" && "text-sm",
        props.disabled && "opacity-50 hover:cursor-not-allowed",
        className,
      )}
    >
      {props.children}
    </Tag>
  );
};
export default Button;
